<div class="user-chat w-100 overflow-hidden">
  <div class="chat-content d-lg-flex">
    <div class="w-100 overflow-hidden position-relative">
      <div class="position-relative">
        <div class="position-relative" id="users-chat">
          <ng-scrollbar class="chat-conversation p-3 p-lg-4" #chatScrollArea id="chatScrollArea">
            @for (data of comments; track $index) {
              <div class="d-flex mb-4">
                <div class="avatar-xs rounded-circle shadow initials-avatar">
                  {{ data.first_name.charAt(0).toUpperCase() }}{{ data.last_name.charAt(0).toUpperCase() }}
                </div>
                <div class="flex-grow-1 ms-3">
                  <h5 class="fs-13">{{ data.first_name }} {{ data.last_name }}
                    - {{ data.role | titlecase }}<small class="text-muted ms-2">{{ data.updated_at | date:'MM/dd/yyyy hh:mm a' }}</small></h5>
                  <p class="text-muted">{{ data.message }}</p>
                </div>
              </div>
            }

            @if (comments.length === 0) {
              <div class="text-center">
                <h5 class="mt-3">No notes found</h5>
              </div>
            }
          </ng-scrollbar>
        </div>
        <div class="chat-input-section pt-10">
          <form (ngSubmit)="notesSave()" [formGroup]="formMessage" id="chatinput-form"
                enctype="multipart/form-data">
            <div class="row g-0 align-items-center">
              <div class="col">
                @if (submittedMessage && messageForm['message'].errors) {
                  <div class="chat-input-feedback">
                    @if (messageForm['message'].errors?.['required']) {
                      <span>Please Enter a Message</span>
                    }
                  </div>
                }
                <input type="text" class="form-control chat-input bg-light border-light"
                       id="chat-input" placeholder="Type your message..." autocomplete="off"
                       formControlName="message">
              </div>
              <div class="col-auto">
                <div class="chat-input-links ms-2">
                  <div class="links-list-item">
                    <button type="submit"
                            class="btn btn-success chat-send waves-effect waves-light shadow">
                      <i class="ri-send-plane-2-fill align-bottom"></i>
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
