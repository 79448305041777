<div class="mt-3">
  <div class="table-card gridjs-border-none">
    <div class="table-responsive">
      <table class="table align-middle custom-datatable"
        id="datatableexample">
        <thead>
          <tr class="bg-light text-muted">
            <th scope="col" style="width: 25px;">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="checkAll" value="option" [(ngModel)]="masterSelected" (change)="checkUncheckAll($event)">
              </div>
            </th>
            <th scope="col" class="sort" (click)="sort('customer')">
              Customer
            </th>
            <th scope="col" class="sort" (click)="sort('contact')">
              Contact
            </th>
            <th scope="col" class="sort" (click)="sort('consent')">
              Consent
            </th>
            <th scope="col" class="sort" (click)="sort('plan')">
              Plan
            </th>
            <th scope="col" class="sort" (click)="sort('applicants')">
              Applicants
            </th>
            <th scope="col" class="sort" (click)="sort('members')">
              Members
            </th>
            <th scope="col" class="sort" (click)="sort('status')">
              Status
            </th>
            <th scope="col" class="sort"
              (click)="sort('effective_date')">
              Effective Date
            </th>
            <th scope="col" class="sort" (click)="sort('agent')">
              Agent
            </th>
            <th scope="col" class="sort"
              (click)="sort('register_date')">
              Register Date
            </th>
            <th scope="col">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          @for (data of paginatedPolicies; track $index) {
            <tr id="p_{{data._id}}">
              <td>
                <input class="form-check-input" type="checkbox"
                  name="checkAll" value="{{data._id}}"
                 [(ngModel)]="data.isChecked"
                  (change)="checkboxChange($event, data)">
              </td>
              <td>
                <span>
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                      <h5 class="fs-14 mb-1">
                        <a routerLink="/policies/policy/{{ data.id }}"
                           class="blue-text">{{ data.first_name }} {{ data.middle_name }} {{ data.last_name }} {{ data.second_last_name }}</a>
                      </h5>
                      <p class="text-muted mb-0">
                        <span class="fw-small column-fw-small">{{ data.updated_at | date:'MM/dd/yyyy hh:mm a' }}</span>
                      </p>
                    </div>
                  </div>
                </span>
              </td>
              <td>
                <span>
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                      <p class="text-muted mb-0">
                        <span class="column-fw-medium">{{ data.email }}</span>
                      </p>
                      <p class="text-muted mb-0">
                        <span class="column-fw-medium">{{ data.phone_number | phoneNumber }}</span>
                      </p>
                    </div>
                  </div>
                </span>
              </td>
              <td>
                <div class="d-flex">
                  @if(data.consent_sent) {
                    <span class="status-consent" [class.sent]="data.consent_sent">
                      <i class="ri-mail-send-fill" ngbTooltip="Sent Consent"></i>
                    </span>
                  }

                  @if(data.signed_consent) {
                    <span class="status-consent ml-5" [class.signed]="data.signed_consent">
                      <i class="ri-mail-check-fill" ngbTooltip="Signed Consent"></i>
                    </span>
                  }

                  @if(data.confirmed_consent) {
                    <span class="status-consent ml-5" [class.confirmed]="data.confirmed_consent">
                      <i class="ri-checkbox-circle-line" ngbTooltip="Confirmed Consent"></i>
                    </span>
                  }
                </div>
              </td>
              <td>
                <span>
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                      <h5 class="fs-14 mb-1">
                        <a routerLink="/policies/policy/{{ data.id }}"
                        class="blue-text">{{ data.company }}</a>
                      </h5>
                      <p class="text-muted mb-0">
                        <span class="column-fw-medium">{{ data.insurance_plan.split(':')[0] }}</span>
                      </p>
                    </div>
                  </div>
                </span>
              </td>
              <td>
                <span class="badge bg-light text-body fs-12 fw-medium">{{ data.number_applicants }}</span>
              </td>
              <td>
                <span class="badge bg-light text-body fs-12 fw-medium">{{ data.number_members }}</span>
              </td>
              <td>
                <span>
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                      <p class="text-muted mb-0">
                        <span class="badge" [ngClass]="data.status | statusName:'class'">{{ data.status | statusName }}</span>
                      </p>
                      <p class="text-muted mb-0">
                        <span class="fw-small column-fw-small">{{ data.processor_assigned | processName:processors }}</span>
                      </p>
                    </div>
                  </div>
                </span>
              </td>
              <td>
                <span>{{ data.effective_date }}</span>
              </td>
              <td>
                <span>{{ data.agent_name }}</span>
              </td>
              <td>
                <span>{{ data.created_at | date:'MM/dd/yyyy hh:mm a' }}</span>
              </td>
              <td>
                <div class="dropdown" ngbDropdown placement="left">
                  <a href="javascript:void(0);" class="arrow-none" role="button"
                    id="dropdownMenuLink1" data-bs-toggle="dropdown" aria-expanded="false"
                    ngbDropdownToggle>
                    <i class="ri-more-2-fill"></i>
                  </a>

                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink1" ngbDropdownMenu>
                    <li><a class="dropdown-item" routerLink="/policies/policy/{{ data.id }}">View</a></li>
                    @if (!isProcessor) {
                      <li><a class="dropdown-item" (click)="confirm_duplicate(data, NoticeSignModal)" >Duplicate</a></li>
                    }
                    @if (!isProcessor && data.status != 'cancelled' && data.status != 'archived' && data.status != 'processed') {
                      <li><a class="dropdown-item" (click)="toChangePolicyState(data.id, 'archived')">Archive</a></li>
                    }
                    @if (!isProcessor && data.status != 'cancelled') {
                      <li><a class="dropdown-item" (click)="toChangePolicyState(data.id, 'cancelled')">Cancel</a></li>
                    }

                    @if ((isAdmin || permissions?.includes('draft')) && (data.status == 'to_process' || data.status == 'by_correction' || data.status == 'archived')) {
                      <li><a class="dropdown-item" (click)="toChangePolicyState(data.id, 'draft')">Draft</a></li>
                    }
                  </ul>
                </div>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
    <div class="px-3 ">
      <div class="row justify-content-md-between align-items-md-center g-0 pagination">
        <div class="col-sm-12 col-md-5">
          <div class="dataTables_info mb-2" id="tickets-table_info"
            role="status" aria-live="polite">
            Showing {{ (page - 1) * pageSize + 1 }} to {{ (page - 1) * pageSize + paginatedPolicies.length }} of {{ totalPolicies }} entries
          </div>
        </div>
        <!-- Pagination -->
        <div class="col-sm-12 col-md-5">
          <div class="text-md-right float-md-end pagination-rounded gridjs-pagination mb-3">
            <ngb-pagination [collectionSize]="totalPolicies" [(page)]="page"
                            [pageSize]="pageSize" (pageChange)="onPageChange($event)"
                            [maxSize]="5"></ngb-pagination>
          </div>
        </div>
        <!-- End Pagination -->
      </div>
    </div>
  </div>
</div>
<div class="tab-pane alltable d-none" id="productnav-draft" role="tabpanel">
  <div class="py-4 text-center">
    <div>
      <!--                                                    <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop" colors="primary:#405189,secondary:#0ab39c" style="width:72px;height:72px">-->
    <!--                                                    </lord-icon>-->
  </div>
  <div class="mt-4">
    <h5>Sorry! No Result Found</h5>
  </div>
</div>
</div>

<ng-template #NoticeSignModal let-modal>
  <div class="modal-body text-center p-5">
    <lord-icon src="https://cdn.lordicon.com/tdrtiskw.json" trigger="loop"
               colors="primary:#f7b84b,secondary:#405189" style="width:130px;height:130px"></lord-icon>
    <div class="mt-4">
      <h4 class="mb-3">Notice</h4>
      <p class="text-muted mb-4">Are you sure you want to duplicate the policy?</p>
      <div class="hstack gap-2 justify-content-center">
        <a href="javascript:void(0);" class="btn btn-link link-success fw-medium shadow-none"
           data-bs-dismiss="modal" (click)="cancelStatusChange(modal)">Cancel</a>
        <a (click)="accept_same_way(); modal.close('accept click')" class="btn btn-success">Accept</a>
      </div>
    </div>
  </div>
</ng-template>
