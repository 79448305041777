import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PolicyRoutingModule } from './policy-routing.module';
import { NgxMaskDirective, provideNgxMask } from "ngx-mask";
import { CdkStep, CdkStepLabel, CdkStepperNext, CdkStepperPrevious } from "@angular/cdk/stepper";
import { NgStepperModule } from "angular-ng-stepper";
import { SharedModule } from "../../shared/shared.module";
import { NewPolicyComponent } from "./new-policy/new-policy.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import {
  NgbDropdown, NgbDropdownMenu, NgbDropdownToggle,
  NgbNav,
  NgbNavContent,
  NgbNavItem,
  NgbNavLink,
  NgbNavOutlet,
  NgbPagination, NgbTooltip
} from "@ng-bootstrap/ng-bootstrap";
import { PolicyRowComponent } from "./policy-row/policy-row.component";
import { DropzoneModule } from "ngx-dropzone-wrapper";
import { PolicyFilesComponent } from "../../shared/policy-files/policy-files.component";
import { SimplebarAngularModule } from "simplebar-angular";
import { StatusNamePipe } from "../../shared/pipes/status-name.pipe";
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FeatherModule } from "angular-feather";
import { allIcons } from 'angular-feather/icons';
import { ChatComponent } from "./new-policy/chat/chat.component";
import { PhoneNumberPipe } from '../../shared/pipes/phone-number.pipe';
import { ProcessNamePipe } from "../../shared/pipes/process-name.pipe";
import { defineElement } from '@lordicon/element';
import lottie from 'lottie-web';
import { InternalNotesComponent } from "./new-policy/internal-notes/internal-notes.component";


@NgModule({
    declarations: [
        NewPolicyComponent,
        PolicyRowComponent
    ],
    imports: [
        CommonModule,
        PolicyRoutingModule,
        SharedModule,
        ReactiveFormsModule,
        NgStepperModule,
        CdkStep,
        NgSelectModule,
        NgxMaskDirective,
        CdkStepLabel,
        CdkStepperNext,
        CdkStepperPrevious,
        NgbNav,
        NgbNavItem,
        NgbNavLink,
        NgbNavContent,
        NgbNavOutlet,
        FormsModule,
        NgbPagination,
        DropzoneModule,
        PolicyFilesComponent,
        SimplebarAngularModule,
        StatusNamePipe,
        NgbDropdown,
        NgbDropdownToggle,
        NgbDropdownMenu,
        CKEditorModule,
        FeatherModule.pick(allIcons),
        ChatComponent,
        NgbTooltip,
        PhoneNumberPipe,
        ProcessNamePipe,
        InternalNotesComponent
    ],
    exports: [
        PolicyRowComponent
    ],
    providers: [
        provideNgxMask()
    ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PolicyModule {
  constructor() {
    defineElement(lottie.loadAnimation);
  }
}
