import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { SimplebarAngularModule } from 'simplebar-angular';
import { NgScrollbar } from 'ngx-scrollbar';
import { PaginatorModule } from 'primeng/paginator';
import { FormControl, FormGroup, ReactiveFormsModule, UntypedFormGroup, Validators } from '@angular/forms';
import { FacadeService } from '../../../../shared/services/facade/facade.service';
import { DatePipe, NgIf, TitleCasePipe } from '@angular/common';
import { Comment } from '../../../../shared/interfaces/comment';

@Component({
  selector: 'app-internal-notes',
  standalone: true,
  imports: [
    SimplebarAngularModule,
    PaginatorModule,
    ReactiveFormsModule,
    NgIf,
    DatePipe,
    TitleCasePipe,
    NgScrollbar
  ],
  templateUrl: './internal-notes.component.html',
  styleUrl: './internal-notes.component.scss'
})
export class InternalNotesComponent implements AfterViewInit, OnInit, OnChanges {
    @Input() policyId: string | null = '';
    @Input() policy_internal_notes: any[] = [];
    @Input() currentUser: any = {};
    @ViewChild(NgScrollbar) scrollbarRef!: NgScrollbar;

    formMessage!: UntypedFormGroup;
    submittedMessage = false;
    comments: any[] = [];

    constructor(private facadeService: FacadeService) {}

    ngOnInit() {
        this.formMessage = new FormGroup({
            message: new FormControl('', Validators.required)
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if(!this.policyId) {
            return;
        }

        if(changes['policy_internal_notes']) {
            this.comments = this.policy_internal_notes;
            this.scrollToBottom();
        }
    }

    ngAfterViewInit() {
        this.scrollToBottom();
    }

    get messageForm() {
        return this.formMessage.controls as any;
    }

    notesSave() {
        const message = this.formMessage.get('message')!.value;
        if (this.submittedMessage || (!message || message.trim() === '')) {
            return;
        }

        this.facadeService.spinner.show();

        this.submittedMessage = true;

        let newNotes: any = {
            message: message,
            created_at: new Date().getTime(),
            updated_at: new Date().getTime(),
            role: '',
            user_id: this.currentUser.id,
            first_name: this.currentUser.first_name,
            last_name: this.currentUser.last_name,
            policy: this.policyId || ''
        };

        this.facadeService.notesService.addNotes(this.policyId, newNotes).subscribe((response: any) => {
            this.submittedMessage = false;
            this.formMessage.reset();
            this.facadeService.spinner.hide();
        }, (error: any) => {
            this.facadeService.spinner.hide();
        });
    }

    private scrollToBottom(): void {
        setTimeout(() => {
            if (this.scrollbarRef) {
                this.scrollbarRef.scrollTo({ bottom: 0, duration: 0 });
            }
        }, 100);
    }

    protected readonly document = document;
}
