import { Injectable } from '@angular/core';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

@Injectable({
  providedIn: 'root'
})
export class DownloadPdfService {
  constructor() { }

  downloadConsent(currentPolicy: any, tipoDeTamaño: 'carta' | 'oficio' = 'carta'): void {
    const content = this.generatePdfContent(currentPolicy);
    const wrapper = document.createElement("div");
    wrapper.insertAdjacentHTML("afterbegin", content);
    document.body.appendChild(wrapper);

    wrapper.style.fontSize = '38px'; // Ajusta el tamaño de la letra aquí
    wrapper.style.lineHeight = '1.5';
    html2canvas(wrapper, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg', 1);

      const pdfSize = tipoDeTamaño === 'oficio' ? [215.9, 355.6] : [215.9, 279.4];
      const pdf = new jsPDF('p', 'mm', pdfSize);
      const margin = 10;
      const contentWidth = pdfSize[0] - margin * 2;
      const contentHeight = (canvas.height * contentWidth) / canvas.width;
      const scaleFactor = Math.min(contentWidth / canvas.width, (pdfSize[1] - margin * 2) / canvas.height);
      const scaledWidth = canvas.width * scaleFactor;
      const scaledHeight = canvas.height * scaleFactor;
      pdf.addImage(imgData, 'JPEG', margin, margin, scaledWidth, scaledHeight);
      pdf.save(`${currentPolicy?.name_customer_signature || 'consentimiento'}.pdf`);
      document.body.removeChild(wrapper);

    }).catch((error) => {
      console.log(error);
    });
  }
  private generatePdfContent(currentPolicy: any): string {
    let annualHouseholdIncome = 0;

    annualHouseholdIncome = parseInt(String(currentPolicy.annual_income));

    currentPolicy.members?.forEach((member: any) => {
      if(member.type_dependency.toLowerCase() === 'spouse' && member.annual_income) {
        annualHouseholdIncome += parseInt(String(member.annual_income));
      }
    });

    return `
      <div>
        <div class="d-flex align-items-center justify-content-center mb-3">
          <img src="./assets/images/AP-logo-Web.png" alt="Logo de la Empresa" style="height: 200px; margin-right: 20px;"/>
        </div>
        <br />
        <p class="mb-3 fw-semibold text-center">Consumer Consent Form</p>
        <br />
        <p style="text-align: justify;">I, <strong>${currentPolicy?.full_name?.toUpperCase()}</strong> (the Customer) acknowledge that the Agent <strong>${currentPolicy.name_agent_signature.toUpperCase()}</strong> from Protection Insurance Agency has collected my personal information to acquire, modify, or terminate a health insurance policy or policies through the health marketplace, under which I or persons named in my application (spouse or dependents) may be insured, as part of my application for new or modified health insurance coverage. I agree to appoint my Insurance Agent before the Health Marketplace and the affiliated companies.</p>
        <ul>
          <li>Number of People in my ${currentPolicy['period']} Tax Return: ${currentPolicy['number_members']}</li>
          <li>Number of People with Health Coverage: ${currentPolicy['number_applicants']}</li>
          <li>Estimated Annual Household Income: ${annualHouseholdIncome}</li>
          <li>Insurance Company and Plan: ${currentPolicy['insurance_plan']}</li>
        </ul>
        <p style="text-align: justify; padding-top: 10px;">My Agent will have access to all my Personally Identifiable Information (PII) and Protected Health Information (PHI) related to health insurance support functions and processes, such as membership maintenance information, plan benefits information and transactions, new product information, and enrollment, modification, and cancellation information. I confirm that my Insurance Agent significantly assisted me with my enrollment/membership. This information is for the purpose of completing an eligibility application for a Qualified Health Plan (QHP), Advance Payments of the Premium Tax Credit (APTC), and Cost-Sharing Reductions (CSR). The use of my Personally Identifiable Information (PII) for any other purpose not described in this agreement is not authorized without the explicit consent of the consumer or authorized representative. Additionally, by signing this consent, I understand that any currently designated Insurance Agent on this Health Insurance will be removed, and the new Producer added will remain in effect until revoked or replaced in writing. This consent is valid for the entire year ${currentPolicy['period']}.</p>
        <p style="text-align: justify; padding-top: 10px;">As a customer, I accept and acknowledge that all information provided to my Insurance Agent and collected by the Health Insurance Marketplace and the Company is true and accurate. Any liability for incorrect information will be assumed by me as the customer. I also acknowledge that the agent explained the entire summary of the health plan benefits to me.</p>
      </div>
      <div class="pt-3 border-top border-top-dashed mt-4">
        <div class="row g-4 pt-3">
          <div class="col-lg-4 col-12 mt-3">
            <h1 class="fw-semibold mb-3">Customer Signature</h1>
            <div class="signature-form" style="padding-left: 15px; font-family: 'Autography-Dolnw', sans-serif; border-bottom: 2px solid; font-size: 86px; min-height: 53px;">
              <span>${currentPolicy.name_customer_signature}</span>
            </div>
            <p class="mb-1 mt-3">Name: <span class="fw-medium">${currentPolicy.full_name}</span></p>
            <p class="mb-1">Date of Birth: <span class="fw-medium">${currentPolicy.date_of_birth}</span></p>
            <p class="mb-1">Phone: <span class="fw-medium">t${currentPolicy.phone_number}</span></p>
            <p class="mb-1">Date and Time: <span class="fw-medium">${currentPolicy.date_customer_signature == 0 ? '' : (new Date(currentPolicy.date_customer_signature)).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true })}</span></p>
            <p class="mb-1">Location: <span class="fw-medium">${currentPolicy.location_customer_signature}</span></p>
            <p class="mb-1">IP: <span class="fw-medium">${currentPolicy.ip_customer_signature}</span></p>
          </div>
          <div class="col-lg-4 col-12 mt-3">
            <h1 class="fw-semibold mb-3">Agent Signature</h1>
            <div class="signature-form" style="font-family: 'Autography-Dolnw', sans-serif; border-bottom: 2px solid; font-size: 86px; min-height: 53px;">
              <span>${currentPolicy.name_agent_signature}</span>
            </div>
            <p class="mb-1 mt-3">Name: <span class="fw-medium">${currentPolicy.name_agent_signature}</span></p>
            <p class="mb-1">NPN: <span class="fw-medium">${currentPolicy.npn_agent_signature}</span></p>
            <p class="mb-1">Date and Time: <span class="fw-medium">${currentPolicy.date_agent_signature == 0 ? '' : (new Date(currentPolicy.date_agent_signature)).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true })}</span></p>
          </div>
          <div class="col-lg-4 col-12 mt-3">
            <h1 class="fw-semibold mb-3">Agent on Record Signature</h1>
            <div class="signature-form" style="font-family: 'Autography-Dolnw', sans-serif; border-bottom: 2px solid; font-size: 86px; min-height: 53px;">
              <span>${currentPolicy.name_agent_signature_record}</span>
            </div>
            <p class="mb-1 mt-3">Name: <span class="fw-medium">${currentPolicy.name_agent_signature_record}</span></p>
            <p class="mb-1">NPN: <span class="fw-medium">${currentPolicy.npn_agent_signature_record}</span></p>
            <p class="mb-1">Date and Time: <span class="fw-medium">${currentPolicy.date_agent_signature_record == 0 ? '' : (new Date(currentPolicy.date_agent_signature_record)).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true })}</span></p>
          </div>
        </div>
      </div>
      <div class="text-muted pt-3 border-top border-top-dashed mt-4 font-size: 16px;">
        <p style="text-align: justify; font-size: 30px;">
          Privacy Notice: The information contained in this document is confidential and intended solely for the use of the individual or entity to whom it is addressed. This document may contain privileged or legally protected material against disclosure under applicable law. If you are not the intended recipient or the person responsible for delivering this document to the intended recipient, (1) be aware that any use, dissemination, forwarding, or copying of this document is STRICTLY PROHIBITED; and (2) notify the sender immediately by phone and proceed to destroy this document without delay.
        </p>
      </div>
    `;
  }
}
