import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import { Options } from 'ngx-slider-v2';
import { NgbNavChangeEvent} from "@ng-bootstrap/ng-bootstrap";
import { PaginationService } from "../../core/services/pagination.service";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Store } from "@ngrx/store";
import { RootReducerState } from "../../store";
import { deletePolicy } from "../../store/Policies/policy.actions";
import { FacadeService } from "../../shared/services/facade/facade.service";
import { UtilService } from '../../shared/services/util/util.service';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrl: './policy.component.scss'
})
export class PolicyComponent implements OnInit {
  breadCrumbItems!: Array<{}>;
  is_loading = true;
  url = "";
  user = [];

  contactsForm!: UntypedFormGroup;
  total: any;

  permissions: any = [];
  allPolicy: any = [];
  originalPolicyList: any = [];
  toProcessPolicy: any = [];
  processingPolicy: any = [];
  processedPolicy: any = [];
  byCorrectionPolicy: any = [];
  archivedPolicy: any = [];
  draftPolicy: any = [];
  cancelledPolicy: any = [];

  activeindex = '1';
  allpublish: any;
  totalpublish: any = 0;

  searchTerm: any;
  searchpolicies: any;
  publishedproduct: any;

  searchResults: any;
  isAdmin: boolean = false;
  isProcessor: boolean = false;
  current_user: any = {};
  selectedFilter: { type: string, value: any } | null = null;
  filters: any = {
    agents: [],
    companies: [],
    states: [],
    processor: []
  };

  selectedPolicies: any[] = [];
  processors: any[] = [];
  selectedProcessor: any = null;

  public selectedFilters: { [key: string]: any[] } = {};

  constructor(private facadeService: FacadeService,
              public service: PaginationService,
              private utilService: UtilService,
              private formBuilder: UntypedFormBuilder,
              private store: Store<{ data: RootReducerState }>) {
    this.utilService.getAuthStateWithClaims().subscribe(({ user, claims }) => {
      this.isAdmin = claims?.role === 'admin';
      this.isProcessor = claims?.role === 'processor';

      this.facadeService.agentService.getAuthAgent().subscribe((data: any) => {
        this.current_user = data;
        this.permissions = data?.permissions;
      });

      this.facadeService.processorService.getProcessors().subscribe((data: any) => {
        this.processors = data.map((processor: any) => ({
          ...processor,
          name: `${processor.first_name} ${processor.last_name}`
        }));
      });

      this.facadeService.policyService.getPolicies().subscribe((data) => {
        this.filters.agents = data.reduce((acc: any, policy: any) => {
          acc[policy.agent_name] = (acc[policy.agent_name] || 0) + 1;
          return acc;
        }, {});

        this.filters.states = data.reduce((acc: any, policy: any) => {
          acc[policy.state] = (acc[policy.state] || 0) + 1;
          return acc;
        }, {});

        this.filters.companies = data.reduce((acc: any, policy: any) => {
          acc[policy.company] = (acc[policy.company] || 0) + 1;
          return acc;
        }, {});

        this.filters.processors = data.reduce((acc: any, policy: any) => {
            if(!policy?.processor_assigned) {
                if(policy.status === 'to_process') {
                    acc['Unassigned'] = (acc['Unassigned'] || 0) + 1;
                }
                return acc;
            }

            let processorName = this.processors.find((processor: any) => processor.id === policy.processor_assigned)?.name;
            acc[processorName] = (acc[processorName] || 0) + 1;

          return acc;
        }, {});

        this.originalPolicyList = [...data];
        this.allPolicy = data;
        this.filterCount();

        this.is_loading = false;
      });
    });
  }

  onProcessorChange(event: any) {
    this.selectedProcessor = event.id;
  }

  assignToProcessor() {
    if (this.selectedProcessor && this.selectedPolicies.length > 0) {
      this.facadeService.policyService.assignToProcessor(this.selectedProcessor, this.selectedPolicies).subscribe(() => {
        this.selectedProcessor = null;
        this.selectedPolicies = [];
      });
    }
  }

  filterCount() {
    this.draftPolicy = this.allPolicy.filter((product: any) => product.status == 'draft');
    this.byCorrectionPolicy = this.allPolicy.filter((product: any) => product.status == 'by_correction');
    this.toProcessPolicy = this.allPolicy.filter((product: any) => product.status == 'to_process');
    if(this.isProcessor || this.isAdmin) {
      this.toProcessPolicy = this.toProcessPolicy.filter((product: any) => product.status == 'to_process');
    }
    this.processingPolicy = this.allPolicy.filter((product: any) => product.status == 'processing');
    this.processedPolicy = this.allPolicy.filter((product: any) => product.status == 'processed');
    this.archivedPolicy = this.allPolicy.filter((product: any) => product.status == 'archived');
    this.cancelledPolicy = this.allPolicy.filter((product: any) => product.status == 'cancelled');

    for (var i = 0; i < this.allPolicy?.length; i++) {
      if (this.allPolicy[i].status == 'to_process') {
        this.totalpublish += 1
      }
    }
  }

  applyFilter(filterType: string, filterValue: any, eventCheck: any = null) {
    let isChecked = eventCheck?.target.checked || false;

    if (!this.selectedFilters[filterType]) {
      this.selectedFilters[filterType] = [];
    }

    if (isChecked) {
      this.selectedFilters[filterType].push(filterValue);
    } else {
      const index = this.selectedFilters[filterType].indexOf(filterValue);
      if (index > -1) {
        this.selectedFilters[filterType].splice(index, 1);
      }
    }

    this.filterPolicies();
    this.updateDependentFilters();
    this.filterCount();
  }

  updateDependentFilters() {
    const filteredByAgents = this.originalPolicyList.filter((policy: any) => {
      if (!this.selectedFilters['agent_name'] || this.selectedFilters['agent_name'].length === 0) {
        return true;
      }
      return this.selectedFilters['agent_name'].includes(policy.agent_name);
    });

    this.filters.states = filteredByAgents.reduce((acc: any, policy: any) => {
      acc[policy.state] = (acc[policy.state] || 0) + 1;
      return acc;
    }, {});

    this.filters.companies = filteredByAgents.reduce((acc: any, policy: any) => {
      acc[policy.company] = (acc[policy.company] || 0) + 1;
      return acc;
    }, {});

    this.filters.processors = filteredByAgents.reduce((acc: any, policy: any) => {
      if (!policy?.processor_assigned) {
        if (policy.status === 'to_process') {
          acc['Unassigned'] = (acc['Unassigned'] || 0) + 1;
        }
        return acc;
      }

      let processorName = this.processors.find((processor: any) => processor.id === policy.processor_assigned)?.name;
      acc[processorName] = (acc[processorName] || 0) + 1;

      return acc;
    }, {});
  }

  filterPolicies() {
    const filtered = this.originalPolicyList.filter((policy: any) => {
      return Object.keys(this.selectedFilters).every(key => {
        if (this.selectedFilters[key].length === 0) {
          return true;
        }
        return this.selectedFilters[key].includes(policy[key]);
      });
    });

    this.allPolicy = filtered;
    this.toProcessPolicy = filtered.filter((policy: any) => policy.status === 'to_process');
    this.processingPolicy = filtered.filter((policy: any) => policy.status === 'processing');
    this.processedPolicy = filtered.filter((policy: any) => policy.status === 'processed');
    this.byCorrectionPolicy = filtered.filter((policy: any) => policy.status === 'by_correction');
    this.draftPolicy = filtered.filter((policy: any) => policy.status === 'draft');
    this.archivedPolicy = filtered.filter((policy: any) => policy.status === 'archived');
    this.cancelledPolicy = filtered.filter((policy: any) => policy.status === 'cancelled');
  }

  ngOnInit(): void {
    /**
     * BreadCrumb
     */
    this.breadCrumbItems = [
      { label: 'Policies', active: true }
    ];

    /**
     * Form Validation
     */
    this.contactsForm = this.formBuilder.group({
      subItem: this.formBuilder.array([]),
    });
  }

  /**
   * Sort table data
   * @param param0 sort the column
   *
   */
  onSort(column: any) {
    this.allPolicy = this.service.onSort(column, this.allPolicy)
  }

  performSearch(): void {
    if (this.searchTerm) {
      this.searchResults = this.originalPolicyList.filter((item: any) => {
        const searchTermLower = this.searchTerm.toLowerCase();
        return (item.first_name && item.first_name.toLowerCase().includes(searchTermLower)) ||
          (item.last_name && item.last_name.toLowerCase().includes(searchTermLower)) ||
          (item.email && item.email.toLowerCase().includes(searchTermLower)) ||
          (item.phone_number && item.phone_number.toString().toLowerCase().includes(searchTermLower));
      });

      if(this.activeindex == '1') {
        this.allPolicy = this.searchResults;
      } else if(this.activeindex == '2') {
          this.toProcessPolicy = this.searchResults.filter((product: any) => product.status == 'to_process');
      } else if(this.activeindex == '3') {
          this.processingPolicy = this.searchResults.filter((product: any) => product.status == 'processing');
      } else if(this.activeindex == '4') {
          this.byCorrectionPolicy = this.searchResults.filter((product: any) => product.status == 'by_correction');
      } else if(this.activeindex == '5') {
          this.draftPolicy = this.searchResults.filter((product: any) => product.status == 'draft');
      } else if(this.activeindex == '6') {
          this.processedPolicy = this.searchResults.filter((product: any) => product.status == 'processed');
      } else if(this.activeindex == '7') {
          this.archivedPolicy = this.searchResults.filter((product: any) => product.status == 'archived');
      } else if(this.activeindex == '8') {
          this.cancelledPolicy = this.searchResults.filter((product: any) => product.status == 'cancelled');
      }
    } else {
      this.allPolicy = [...this.originalPolicyList];
      this.filterCount();
    }
  }

  changePage() {
    this.allPolicy = this.service.changePage(this.allPolicy)
  }

  resetSelects() {
    this.selectedPolicies = [];
  }

  /**
   * change navigation
   */
  onNavChange(changeEvent: NgbNavChangeEvent) {
    this.resetSelects();

    if (changeEvent.nextId === 1) {
      this.activeindex = '1'
    } else if (changeEvent.nextId === 2) {
      this.activeindex = '2'
    } else if (changeEvent.nextId === 3) {
      this.activeindex = '3'
    } else if (changeEvent.nextId === 4) {
        this.activeindex = '4'
    } else if (changeEvent.nextId === 5) {
        this.activeindex = '5'
    } else if (changeEvent.nextId === 6) {
        this.activeindex = '6'
    } else if (changeEvent.nextId === 7) {
        this.activeindex = '7'
    } else if (changeEvent.nextId === 8) {
        this.activeindex = '8'
    }
  }

  /**
   * Delete Model Open
   */
  deleteId: any;

  // Delete Data
  deleteData(id: any) {
    if (id) {
      this.store.dispatch(deletePolicy({ id: this.deleteId.toString() }));
    } else {
      this.store.dispatch(deletePolicy({ id: this.checkedValGet.toString() }));
      (document.getElementById("selection-element") as HTMLElement).style.display = "none"
    }
    this.deleteId = ''
  }

  // Price Slider
  minValue = 0;
  maxValue = 1000;
  options: Options = {
    floor: 0,
    ceil: 1000
  };

  Default = [
    { name: 'Watches' },
    { name: 'Headset' },
    { name: 'Sweatshirt' },
  ];

  checkedValGet: any[] = [];

  onCheckboxChange(e: any) {
    var checkboxes: any = document.getElementsByName('checkAll');
    var checkedVal: any[] = [];
    var result
    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked) {
        result = checkboxes[i].value;
        checkedVal.push(result);
      }
    }
    this.checkedValGet = checkedVal
    var checkBoxCount: any = document.getElementById('select-content') as HTMLElement;
    checkBoxCount.innerHTML = checkedVal.length;
    checkedVal.length > 0 ? (document.getElementById("selection-element") as HTMLElement).style.display = "block" : (document.getElementById("selection-element") as HTMLElement).style.display = "none";
  }

  clearAll(ev: any) {
    this.selectedFilters = {};
    this.allPolicy = [...this.originalPolicyList];
    this.filterPolicies();
    this.filterCount();

    const checkboxes = document.querySelectorAll('.form-check-input');
    checkboxes.forEach((checkbox: any) => {
      checkbox.checked = false;
    });

    this.filters.states = this.originalPolicyList.reduce((acc: any, policy: any) => {
      acc[policy.state] = (acc[policy.state] || 0) + 1;
      return acc;
    }, {});

    this.filters.companies = this.originalPolicyList.reduce((acc: any, policy: any) => {
      acc[policy.company] = (acc[policy.company] || 0) + 1;
      return acc;
    }, {});

    this.filters.processors = this.originalPolicyList.reduce((acc: any, policy: any) => {
      if (!policy?.processor_assigned) {
        if (policy.status === 'to_process') {
          acc['Unassigned'] = (acc['Unassigned'] || 0) + 1;
        }
        return acc;
      }

      let processorName = this.processors.find((processor: any) => processor.id === policy.processor_assigned)?.name;
      acc[processorName] = (acc[processorName] || 0) + 1;

      return acc;
    }, {});
  }

  selectedPoliciesChange($event: any[]) {
    this.selectedPolicies = $event;
  }

  exportTable() {
    const headers = ['Policy ID', 'Agency', 'Agent', 'First Name', 'Middle Name', 'Last Name', 'Second Last Name',
      'Date of Birth', 'Gender', 'Email', 'Phone Number', 'Legal Status', 'Green Card', 'Work Permit', 'Civil Status',
      'Address 1', 'Address 2', 'City', 'State', 'Postal Code', 'County', 'Name of Employer', 'Employer Phone', 'Occupation',
      'Annual Income', 'Policy Number', 'Contact Identification', 'Number of Applicants', 'Number of Members', 'Number of Dependents',
      'Effective Date', 'Company', 'Insurance Plan', 'Type of Plan', 'Tax Credit/Subsidy', 'Monthly Premium Amount', 'Status',
      'Period', 'Confirmed Consent', 'Created At', 'Updated At'];

    const policyKeys = ['id', 'agency', 'agent_name', 'first_name', 'middle_name', 'last_name', 'second_last_name',
      'date_of_birth', 'gender', 'email', 'phone_number', 'legal_status', 'green_card', 'work_permit', 'civil_status',
      'address_1', 'address_2', 'city', 'state', 'postal_code', 'county', 'name_of_employer', 'employer_phone', 'position_occupation',
      'annual_income', 'policy_number', 'contact_identification', 'number_applicants', 'number_members', 'number_dependents',
      'effective_date', 'company', 'insurance_plan', 'type_plan', 'tax_credit_subsidy', 'monthly_premium_amount', 'status',
      'period', 'confirmed_consent', 'created_at', 'updated_at'];
    let rows: any[] = [];

    const mapPolicies = (policies: any[]) => {
      return policies.map((policy: any) => {
        const row = policyKeys.map(key => {
          if (key === 'created_at' || key === 'updated_at') {
            const date = new Date(policy[key]);
            return date.toLocaleDateString('en-US');
          }

          if (key === 'date_of_birth' || key === 'effective_date') {
            const date = new Date(policy[key]);
            return date.toLocaleDateString('en-US');
          }

          return policy[key];
        });

        if (policy.members && policy.members.length > 0) {
          policy.members.forEach((member: any, index: number) => {
            Object.keys(member).forEach(memberKey => {
              const memberHeader = `Member ${index + 1} ${memberKey.replace('_', ' ').toUpperCase()}`;
              if (!headers.includes(memberHeader)) {
                headers.push(memberHeader);
              }
              row.push(member[memberKey]);
            });
          });
        }

        return row;
      });
    };

    switch (this.activeindex) {
      case '1':
        rows = mapPolicies(this.allPolicy);
        break;
      case '2':
        rows = mapPolicies(this.toProcessPolicy);
        break;
      case '3':
        rows = mapPolicies(this.processingPolicy);
        break;
      case '4':
        rows = mapPolicies(this.byCorrectionPolicy);
        break;
      case '5':
        rows = mapPolicies(this.draftPolicy);
        break;
      case '6':
        rows = mapPolicies(this.processedPolicy);
        break;
      case '7':
        rows = mapPolicies(this.archivedPolicy);
        break;
      case '8':
        rows = mapPolicies(this.cancelledPolicy);
        break;
    }

    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...rows]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Policies');

    XLSX.writeFile(workbook, 'policies.xlsx');
  }
}
